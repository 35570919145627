import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AngularFullpageModule } from '@fullpage/angular-fullpage';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { DeviceDetectorService } from 'ngx-device-detector';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { AuroraComponent } from './components/aurora/aurora.component';
import { RecirculatingComponent } from './components/recirculating/recirculating.component';
import { BatchComponent } from './components/batch/batch.component';
import { CloudComponent } from './components/cloud/cloud.component';
import { ContactComponent } from './components/contact/contact.component';
import { SupportComponent } from './components/support/support.component';
import { RedirectGuard } from './services/redirect.guard';
import { InfluencerComponent } from './components/contacts/influencer/influencer.component';
import { InstallerComponent } from './components/contacts/installer/installer.component';
import { DistributerComponent } from './components/contacts/distributer/distributer.component';
import { ElixirComponent } from './components/elixir/elixir.component';
import { HowToWorkSectionComponent } from './components/how-to-work-section/how-to-work-section.component';
import { HeroSectionComponent } from './components/hero-section/hero-section.component';
import { MatDialogModule } from '@angular/material/dialog';
import { ModalPreOrderComponent } from './components/modal-pre-order/modal-pre-order.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FluxComponent } from './components/flux/flux.component';
import { MatMenuModule } from '@angular/material/menu';
import { MainComponent } from './components/main/main.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    AuroraComponent,
    RecirculatingComponent,
    BatchComponent,
    CloudComponent,
    ContactComponent,
    SupportComponent,
    InfluencerComponent,
    InstallerComponent,
    DistributerComponent,
    ElixirComponent,
    HowToWorkSectionComponent,
    HeroSectionComponent,
    ModalPreOrderComponent,
    FluxComponent,
    MainComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AngularFullpageModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MatDialogModule,
    BrowserAnimationsModule,
    MatMenuModule
  ],
  providers: [
    DeviceDetectorService,
    RedirectGuard,
    {
      provide: 'externalUrlRedirectResolver',
      useValue: (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
        window.location.href = (route.data as any).externalUrl;
      }
    }
  ],
  bootstrap: [AppComponent],
  entryComponents: [ModalPreOrderComponent]
})
export class AppModule {
}
