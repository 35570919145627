import { Component, OnInit, Inject } from '@angular/core';
import { DOCUMENT, Location } from '@angular/common';
import { Router } from "@angular/router";

@Component({
  selector: 'app-support',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.scss']
})
export class SupportComponent implements OnInit {

  constructor(
    private location: Location,
    private router: Router,
    @Inject(DOCUMENT) private document: Document
  ) {
  }

  ngOnInit() {
  }

  goToUrl(url: string): void {
    this.document.location.href = 'https://' + url;
  }

  goTo(route: string) {
    const parsedUrl = new URL(window.location.href);
    const baseUrl = parsedUrl.origin;
    this.document.location.href = `${baseUrl}${route}`;
  }

}
