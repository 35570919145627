import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { MobileService } from './services/mobile.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit {
  route: string;
  headerType: string;
  headerTheme: string;
  redirect = false;

  constructor(
    private location: Location,
    private router: Router,
    private mobileService: MobileService,
  ) {
    this.redirect = location.path() === '/guide';

    router.events.subscribe(val => {
      this.headerType = 'absolute';
      this.headerTheme = 'dark';

      if (location.path() !== '') {
        this.route = location.path();
        switch (this.route) {
          case '/main-old':
            this.headerType = 'sticked';
            this.headerTheme = '';
            break;

          case '/support':
          // case '/aurora':
          case '/':
            this.headerTheme = 'dark';
            break;
          case '/contact':
            this.headerTheme = 'light-blur';
            break;
          case '/influencer':
            this.headerTheme = 'light-blur';
            break;
          case '/installer':
            this.headerTheme = 'light-blur';
            break;
          case '/distributer':
            this.headerTheme = 'light-blur';
            break;
        }
      }
    });
  }

  ngOnInit() {
  }

  onActivate(event) {
    this.mobileService.setSliderSlideType(undefined);
    window.scrollTo(0, 0);
  }

}
