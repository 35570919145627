import { Component, OnInit, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-modal-pre-order',
  templateUrl: './modal-pre-order.component.html',
  styleUrls: ['./modal-pre-order.component.scss']
})
export class ModalPreOrderComponent implements OnInit {


  constructor(public dialogRef: MatDialogRef<ModalPreOrderComponent>) {

  }

  ngOnInit() {
  }

  onSubmit() {
    this.dialogRef.close();
  }

}
