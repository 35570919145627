import { ChangeDetectionStrategy, Component, Inject, Input } from '@angular/core';
import { DOCUMENT } from "@angular/common";

@Component({
  selector: 'app-hero-section',
  templateUrl: './hero-section.component.html',
  styleUrls: ['./hero-section.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeroSectionComponent {
  @Input() mainTitle;
  @Input() heroTitle;
  @Input() image;
  @Input() heroSubtitle;
  @Input() buttonText;
  @Input() buttonLink;


  constructor(@Inject(DOCUMENT) private document: Document) {
  }

  goToUrl(url: string): void {
    this.document.location.href = 'https://' + url;
  }

}
