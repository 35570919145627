import { ChangeDetectionStrategy, OnChanges, Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { NgForm } from '@angular/forms';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-distributer',
  templateUrl: './distributer.component.html',
  styleUrls: ['../contacts.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DistributerComponent implements OnInit, OnChanges {
  @ViewChild('f', { static: false }) signUpForm: NgForm;
  messageHasBeenSent = false;

  constructor(
    private http: HttpClient,
    private changeDetection: ChangeDetectorRef
  ) {
  }

  ngOnChanges(changes) {
    console.log(changes);
  }

  ngOnInit() {
  }

  onSubmit() {
    const f = this.signUpForm;
    const message = `
      First Name: ${f.value.firstName || 'not specified'};
      Last Name: ${f.value.lastName || 'not specified'};
      Email: ${f.value.email || 'not specified'};
      phone: ${f.value.phone || 'not specified'};
      title: ${f.value.title || 'not specified'};
      company: ${f.value.company || 'not specified'};
      address: ${f.value.address || 'not specified'};
      city: ${f.value.city || 'not specified'};
      state: ${f.value.state || 'not specified'};
      zip: ${f.value.zip || 'not specified'};
      haveTaxId: ${f.value.haveTaxId || 'not specified'};
      socialMedia: ${f.value.socialMedia || 'not specified'};
      automationProducts: ${f.value.automationProducts || 'not specified'};
      experience: ${f.value.experience || 'not specified'};
      message: ${f.value.message || 'not specified'};`;

    this.postData(message).subscribe((res) => {
      this.signUpForm.reset();
      this.messageHasBeenSent = true;
      this.changeDetection.detectChanges();
    }, (error) => {
      console.log(error);
    });
  }

  postData(data: any) {
    const url = 'https://77d14ljom7.execute-api.us-east-1.amazonaws.com/Production/contact';
    return this.http.post(url, { data });
  }


}
