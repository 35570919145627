import { Component, OnInit, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-cloud',
  templateUrl: './cloud.component.html',
  styleUrls: ['./cloud.component.scss']
})
export class CloudComponent implements OnInit {
  public vh100: number;
  public isMobile: boolean;
  public isSafari: any;
  private os: string[] = ["iOS", "Mac", "iPadOS"];

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private deviceService: DeviceDetectorService
  ) {
    this.detectDevice();
    this.vh100 = window.innerHeight;
  }

  ngOnInit() {
  }

  detectDevice() {
    const deviceInfo = this.deviceService.getDeviceInfo();
    this.isSafari = this.os.includes(deviceInfo.os);
    this.isMobile = this.deviceService.isMobile();
  }

  goToUrl(url: string): void {
    this.document.location.href = 'https://' + url;
  }

}
