import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
  @ViewChild('f', {static: false}) signUpForm: NgForm;
  messageHasBeenSent = false;

  constructor(
    private http: HttpClient,
  ) { }

  ngOnInit() {
  }

  onSubmit() {
    const f = this.signUpForm;
    const message = `First Name: ${f.value['firstName'] || 'not specified'}; Last Name: ${f.value['lastName'] || 'not specified'}; Email: ${f.value['email'] || 'not specified'}; Phone: ${f.value['phone'] || 'not specified'}; Message: ${f.value['message'] || 'not specified'}`;

    this.postData(message).subscribe((res) => {
        f.value['firstName'] = '';
        f.value['lastName'] = '';
        f.value['email'] = '';
        f.value['phone'] = '';
        f.value['message'] = '';
        this.messageHasBeenSent = true;
      }, (error) => {
        console.log(error);
      });
  }

  postData(data: any) {
    const url = 'https://77d14ljom7.execute-api.us-east-1.amazonaws.com/Production/contact';
    return this.http.post(url, {data});
  }
}
