import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-influencer',
  templateUrl: './influencer.component.html',
  styleUrls: ['../contacts.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InfluencerComponent implements OnInit {
  @ViewChild('f', { static: false }) signUpForm: NgForm;
  messageHasBeenSent = false;
  constructor(
    private http: HttpClient,
    private changeDetection: ChangeDetectorRef
  ) {
  }

  ngOnInit() {
  }

  onSubmit() {
    const f = this.signUpForm;
    const message = `
      First Name: ${f.value.firstName || 'not specified'};
      Last Name: ${f.value.lastName || 'not specified'};
      Email: ${f.value.email || 'not specified'};
      Country: ${f.value.country || 'not specified'};
      Instagram: ${f.value.instagram || 'not specified'};
      TikTok: ${f.value.tikTok || 'not specified'};
      YouTube: ${f.value.youtube || 'not specified'};
      LinkedIn: ${f.value.linkedIn || 'not specified'};
      Message: ${f.value.message || 'not specified'}`;

    this.postData(message).subscribe((res) => {
      this.signUpForm.reset();
      this.messageHasBeenSent = true;
      this.changeDetection.detectChanges();
    }, (error) => {
      console.log(error);
    });
  }

  postData(data: any) {
    const url = 'https://77d14ljom7.execute-api.us-east-1.amazonaws.com/Production/contact';
    return this.http.post(url, { data });
  }
}
