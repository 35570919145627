import { Component, OnInit, Inject } from '@angular/core';
import { DOCUMENT, Location } from '@angular/common';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ModalPreOrderComponent } from '../modal-pre-order/modal-pre-order.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-batch',
  templateUrl: './batch.component.html',
  styleUrls: ['./batch.component.scss']
})
export class BatchComponent implements OnInit {
  public vh100: number;
  public isMobile: boolean;
  public isSafari: any;
  private os: string[] = ['iOS', 'Mac', 'iPadOS'];

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private deviceService: DeviceDetectorService,
    public matDialog: MatDialog,
    private location: Location,
    private router: Router
  ) {
    this.detectDevice();
    this.vh100 = window.innerHeight;
  }

  ngOnInit() {
  }

  detectDevice() {
    const deviceInfo = this.deviceService.getDeviceInfo();
    this.isSafari = this.os.includes(deviceInfo.os);
    this.isMobile = this.deviceService.isMobile();
  }

  goToUrl(url: string): void {
    this.document.location.href = 'https://' + url;
  }

  openModal() {
    const dialogConfig = new MatDialogConfig();
    // dialogConfig.id = 'modal-component';
    // dialogConfig.height = '600px';
    // dialogConfig.width = '800px';
    const modalDialog = this.matDialog.open(ModalPreOrderComponent, dialogConfig);
  }

   goTo(route: string) {
    const parsedUrl = new URL(window.location.href);
    const baseUrl = parsedUrl.origin;
    this.document.location.href = `${baseUrl}${route}`;
  }
}
