import { Component, OnInit, Inject, Input } from '@angular/core';
import { Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';

import { MobileService } from '../../services/mobile.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  @Input() theme;
  @Input() type;

  public isNavOpened = false;
  public notFirstSlideActive: boolean = undefined;

  private sliderSlideTypeSubscription: any;

  public showHardwareMenu = false;
  public showContactMenu = false;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private mobileService: MobileService,
    private router: Router
  ) {
  }

  ngOnInit() {
    this.sliderSlideTypeSubscription = this.mobileService.sliderSlideType.subscribe(
      (res: boolean) => {
        this.notFirstSlideActive = res;
      },
      (err: any) => console.error(err),
    );
  }

  openNavMobile() {
    this.isNavOpened = true;
  }

  closeNavMobile() {
    this.isNavOpened = false;
  }

  toggleNavMobile(status: boolean) {
    this.isNavOpened = !status;
  }

  goTo(route: string) {
    this.closeNavMobile();
    this.router.navigate(['/' + route]);
    this.showHardwareMenu = false;
    this.showContactMenu = false;
  }

  goToUrl(url: string): void {
    this.document.location.href = 'https://' + url;
  }

  onMouseleave(event: any, eventName) {
    this[eventName] = false;
  }

  onMouseenter(event: any, eventName) {
    this[eventName] = true;
  }
}
